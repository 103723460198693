import config from '@etherealengine/common/src/config'
import React from 'react'

export const Redirect = () => {
  const path = new URLSearchParams(location.search)?.has('error') ? `/dashboard${location.search}` : '/dashboard'
  const url = `${config.client.clientUrl}${path}`

  window.location.href = url
  return <></>
}

export default Redirect
